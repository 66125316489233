import {filterBy, process} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import {useEffect, useRef, useState} from "react";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import Waiting from "../waiting/Waiting";
import EditCommandCell from "../editComponents/EditCommandCell";
import APICall from "../../tools/APICall";
import CurrencyEditor from "../editComponents/CurrencyEditor";
import DropDownFilter from "../filterComponents/DropDownFilter";
import DropDownEditor from "../editComponents/DropDownEditor";
import DurationEditor from "../editComponents/DurationEditor";
import useClientStore from "../../stores/ClientStore";
import DateFilter from "../filterComponents/DateFilter";
import useCashflowStore from "../../stores/CashflowStore";
import useTmsStore from "../../stores/TmsStore";
import useCurrencyStore from "../../stores/CurrencyStore";
import useProjectStore from "../../stores/ProjectStore";
import CommandCell from "../tableComponents/CommandCell";
import Confirm from "../general/Confirm";
import FileDialog from "../files/FileDialog";
import EditTms from "./EditTms";
import ImportDialog from "./ImportDialog";
import {Button, ToolbarItem, ToolbarSeparator, ToolbarSpacer} from "@progress/kendo-react-buttons";
import BulkEditTms from "./BulkEditTms";
import useOwnRightsStore from "../../stores/OwnRightsStore";
import ConfirmRecalculate from "./ConfirmRecalculate";
import Partner from "../lists/partner/Partner";
import {ExcelExport, ExcelExportColumn} from "@progress/kendo-react-excel-export";

const comparisonDate = new Date().getDate() > 5 ? new Date(new Date().getFullYear(), new Date().getMonth(), 1) : new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);

const ActionCell = (props) => {
    return(
        <>
            {/*<Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"*/}
            {/*        size="small"*/}
            {/*        rounded="small"*/}
            {/*        onClick={() => props.enterEdit(props.dataItem)}*/}
            {/*>Novy klient</Button>*/}
            {/*<span className={"table-header-action"}>Nový klient</span>*/}
            {/*<span className={"table-header-action"}>aaa</span>*/}
        </>
    )
}

const initialDataState = {
    // take: 1000,
    skip: 0,
    // pageSize:10,
    take:50,
    sort:[
        {dir: "desc", field: "Date" }
    ],
};


const TmsGrid = (props) => {

    const _grid = useRef();
    const _export = useRef(null);



    const isSmallHeight = window.innerHeight < 600

    // const refresh = props.refresh
    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))
    const {selectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem}))

    const refSize = props.size
    const menuSize = props.menuSize
    const dataType = props.dataType || "all"

    console.log("tms grid size", refSize, menuSize)


    const [dataState, setDataState] = useState(initialDataState);
    const [refresh, setRefresh] = useState(Date.now())


    let columnDefWidth = 100
    // let columnDefWidth = refSize.width * 0.1
    // if (columnDefWidth < 120) columnDefWidth = 120


    useEffect(() => {
        switch (dataType) {
            case "client":
                loadTmsForClient(selectedClient.ID)
                break
            case "project":
                loadTmsForProject(selectedProject.ID)
                break
            default:
                loadTmsAll()
        }
    },[selectedClient, selectedProject])

    //
    // let apiURL, apiProjectURL;
    // switch (dataType) {
    //     case "client":
    //         apiURL = config.API_URL + 'tms/forClient/' + selectedClient.ID
    //         apiProjectURL = config.API_URL + 'project/forClient/' + selectedClient.ID
    //         break
    //     case "project":
    //         apiURL = config.API_URL + 'tms/forProject/' + selectedProject.ID
    //         apiProjectURL = config.API_URL + 'project/forProject/' + selectedProject.ID
    //         break
    //     default:
    //         apiURL = config.API_URL + 'tms/'
    //         apiProjectURL = config.API_URL + 'project/'
    // }

    const haveRight = useOwnRightsStore(state => state.haveRight)

    const data = useTmsStore(state => state.tms)
    const isLoading = useTmsStore(state => state.isLoading)
    const setData = useTmsStore(state => state.setTms)
    const loadTmsForClient = useTmsStore(state => state.loadTmsForClient)
    const loadTmsForProject = useTmsStore(state => state.loadTmsForProject)
    const loadTmsAll = useTmsStore(state => state.loadTmsAll)

    const tms = useTmsStore(state => state.tmsOriginal)
    const addNew = useTmsStore(state => state.addNew)
    const discardNew = useTmsStore(state => state.discardNew)
    const saveNew = useTmsStore(state => state.saveNew)
    const saveItem = useTmsStore(state => state.saveItem)
    const deleteItem = useTmsStore(state => state.deleteItem)
    const refreshData = useTmsStore(state => state.refreshData)
    const recalculatePrice = useTmsStore(state => state.recalculatePrice)
    const recalculatePriceUnInvoiced = useTmsStore(state => state.recalculatePriceUnInvoiced)



    const { data:currency, isPending:currency_isPending, error:currency_error} = useFetch(config.API_URL + 'lists/currency/', refresh)


    // const [selectedCount, setSelectedCount] = useState(0)

    // useEffect(() => {
    //     setSelectedCount(process(data, dataState).data.length)
    // },[dataState])


    // const { data:tms, isPending, error} = useFetch(apiURL, refresh)

    const { data:workTypes, isPending:workType_isPending, error:workType_error} = useFetch(config.API_URL + 'lists/worktype/', refresh)
    const { data:authors, isPending:authors_isPending, error:authors_error} = useFetch(config.API_URL + 'user/authors/', refresh)
    const { data:partners, isPending:partners_isPending, error:partners_error} = useFetch(config.API_URL + 'partner/', refresh)
    const { data:projects, isPending:projects_isPending, error:projects_error} = useFetch(config.API_URL + 'project/for_lists', refresh)
    const { data:actual_user, isPending:actual_user_isPending, error:actual_user_error} = useFetch(config.API_URL + 'user/get_own_user', 1)

    const SumFooter = (props) => {
        const field = props.field || "";

        const filteredData = dataState.filter ? filterBy(data, dataState.filter) : data;
        const total = filteredData.reduce((acc, current) => acc + current[field], 0);

        // const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
        const formatter = new Intl.NumberFormat('en-US', { useGrouping: true });
        return (
            <td colSpan={props.colSpan} style={props.style} className={"right"}>
                {formatter.format(total)}
            </td>
        );
    };

    const DurationFooter = (props) => {
        const field = props.field || "";

        const filteredData = dataState.filter ? filterBy(data, dataState.filter) : data;
        const total = filteredData.reduce((acc, current) => acc + current[field], 0);

        // const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
        const dataValue = Math.floor(total / 60).toString().padStart(2, '0') + ':' + (total % 60).toString().padStart(2, '0')
        return (
            <td colSpan={props.colSpan} style={props.style} className={"right"}>
                {dataValue}
            </td>
        );
    };

    const CountFooter = (props) => {
        const field = props.field || "";

        const filteredData = dataState.filter ? filterBy(data, dataState.filter) : data;
        const total = filteredData.length

        // const total = process(data, dataState).data.length
        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {total} záznamů
            </td>
        );
    };



    const PriceFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
        const formatter = new Intl.NumberFormat('cs-CZ', { useGrouping: true });
        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className + " right"} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {formatter.format(total)}
            </td>
        );
    };

    // useEffect(() => {
    //     switch (dataType) {
    //         case "client":
    //             loadTmsForClient(selectedClient.ID)
    //             break
    //         case "project":
    //             loadTmsForProject(selectedProject.ID)
    //             break
    //         default:
    //             loadTmsAll()
    //     }
    // },[selectedClient, selectedProject])
    //

    //region editors and filters



    const useCurrencyEditor = props => (
        <DropDownEditor
            {...props}
            listData={currency}
            editable={true}
        />
    );

    const CurrencyFilter = (props) => (
        <DropDownFilter
            {...props}
            data={currency}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );


    const useProjectEditor = props => (
        <DropDownEditor
            {...props}
            listData={projects}
            // columns={projectColumns}
            textField={"ClientProjectName"}
            editable={true}
            filterable={true}
        />
    );

    const ProjectFilter = (props) => (
        <DropDownFilter
            {...props}
            data={projects}
            textField={"ClientProjectName"}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useWorkTypeEditor = props => (
        <DropDownEditor
            {...props}
            listData={workTypes}
            editable={true}
        />
    );



    const WorkTypeFilter = (props) => (
        <DropDownFilter
            {...props}
            data={workTypes}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useAuthorEditor = props => (
        <DropDownEditor
            {...props}
            listData={authors}
        />
    );

    const useDurationEditor = props => (
        <DurationEditor
            {...props}
        />
    );

    const AuthorFilter = (props) => (
        <DropDownFilter
            {...props}
            data={authors}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useDateFilter = (props) => (
        <DateFilter
            {...props}
            defaultItem={{ID: 0, Name: "All"}}
            type={"past"}
            dataState={dataState}
            setDataState={setDataState}
        />
    );



    const usePartnerEditor = props => (
        <DropDownEditor
            {...props}
            listData={partners}
        />
    );

    const PartnerFilter = (props) => (
        <DropDownFilter
            {...props}
            data={partners}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );



    //endregion


    //region edit functions


    const MyEditCommandCell = (props) => (
        <CommandCell
            {...props}
            edit={enterEdit}
            copy={enterCopy}
            delete={deleteRow}
            showFiles={showFiles}
            filesCount={props.dataItem.FilesCount}
            disableDelete={(props.dataItem?.Date < comparisonDate && !haveRight("TMS_Edit_Locked")) || (props.dataItem?.AuthorID !== actual_user?.ID && !haveRight("TMS_Fill_For_Others"))}
        />
    );



    //endregion


    // const recalculatePrice = (e) => {
    //
    // }


    // ********************************* helper data  *********************************

    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState(null);

    const [openFormBulk, setOpenFormBulk] = useState(false);
    const [openFormRecalculate, setOpenFormRecalculate] = useState(false);


    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [deletedItem, setDeletedItem] = useState(null);

    const [showFilesDialog, setShowFilesDialog] = useState(false)
    const [showFilesFor, setShowFilesFor] = useState(null);

    const [showImportDialog, setShowImportDialog] = useState(false)


// ************************************** EDIT *************************************


    const rowDblClick = props => {
        enterEdit(props.dataItem)
    }

    const enterEdit = (dataItem) => {
        console.log("item edit")

        setEditItem(dataItem)
        setOpenForm(true)
    };


    const handleCancelEdit = (dataItem) => {

        // debugger
        setOpenForm(false);
        refreshData()


    };



    const handleSubmit = (dataItem) => {
        // debugger
        if (dataItem.ID === undefined || dataItem.ID === null) {
            saveNew(dataItem)
        }
        else {
            saveItem(dataItem)
        }
        setOpenForm(false);
    };


    // ************************************** NEW *************************************

    // const editNew = () => {
    //     const newItem = {};
    //     setEditItem(newItem)
    //     setOpenForm(true)
    // };

    const editNew = () => {
        const newItem = addNew() // {};

        //debugger

        const now = new Date()
        newItem.Date = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        const newItem2 = {
            ...newItem,
            TimeSpentIn: 0,
            TimeSpentOut: 0,
            AuthorID: actual_user.ID,
            // ImportID: null
        }

        setEditItem(newItem2)
        setOpenForm(true)
    };

    // ************************************** COPY *************************************


    const enterCopy = (dataItem) => {
        console.log("copy edit")

        const newItem = {
            ...dataItem,
            ID: null,
            UID: null,
            Origin: null,
            ImportID: null
        }

        setEditItem(newItem)
        setOpenForm(true)

        console.log("edit copy ... ", openForm, editItem)
    };



    // ************************************** Delete *************************************

    const deleteRow = (dataItem) => {
        setDeletedItem(dataItem)
        setShowConfirmDelete(true)
    };

    const deleteRowAction = () => {
        deleteItem(deletedItem)
        setShowConfirmDelete(false)
    };

    const cancelDelete = () => {
        setShowConfirmDelete(false)
    };


    // ************************************** Files *************************************

    const showFiles = (dataItem) => {
        // debugger
        setShowFilesFor(dataItem)
        setShowFilesDialog(true)
    };

    const closeFiles = () => {
        refreshData()
        setShowFilesDialog(false)
    };


    // ************************************** Files *************************************

    const showImport = (dataItem) => {
        // debugger
        setShowFilesFor(dataItem)
        setShowImportDialog(true)
    };

    const closeImport= () => {
        refreshData()
        setShowImportDialog(false)
    };


    // ************************************** Bulk *************************************

    const bulkEdit = () => {
        setOpenFormBulk(true)
    };

    const bulkClose = () => {
        setOpenFormBulk(false)
    }

    const bulkSubmit = () => {
        setOpenFormBulk(false)
    }

    // ******************************* Recalculate price *******************************

    const Recalculate = () => {
        setOpenFormRecalculate(true)
    };

    const RecalculateClose = () => {
        setOpenFormRecalculate(false)
    }

    const RecalculateAll = () => {
        recalculatePrice()
        setOpenFormRecalculate(false)
    }

    const RecalculateUnInvoiced = () => {
        recalculatePriceUnInvoiced()
        setOpenFormRecalculate(false)
    }


    // ************************************** EXPORT *************************************

    const excelExport = () => {
        if (_export.current !== null) {
            // _export.current.save(process(data, dataState));
            _export.current.save(data);
        }
    };


    // ************************************** GRID *************************************


    // const [page, setPage] = useState(initialDataState);
    const [pageSizeValue, setPageSizeValue] = useState(50);

    const pageChange = (event) => {

        // const targetEvent = event.syntheticEvent;
        // const take = targetEvent.value === "All" ? process(data, dataState).length : event.page.take;
        //
        // if (targetEvent.value) {
        //     setPageSizeValue(targetEvent.value);
        // }

        setPageSizeValue(event.page.take)

        setDataState({
            ...dataState,
            skip: event.page.skip,
            take: event.page.take
        });
    };

    // noinspection BadExpressionStatementJS
    return(
        <>

            <ExcelExport ref={_export} >
                <ExcelExportColumn field="ID" title="ID" locked={true} width={50} />
                <ExcelExportColumn field="ClientName" title="Klient" locked={false} width={120} />
                <ExcelExportColumn field="ProjectName" title="Projekt" locked={false} width={120} />
                <ExcelExportColumn field="AuthorName" title="Autor" locked={false} width={120} />
                {haveRight("View_Out_Values") && <ExcelExportColumn field="AuthorOutName" title="Autor Out" locked={false} width={120} />}
                <ExcelExportColumn field="Date" title="Datum" locked={false} width={120} />
                <ExcelExportColumn field="WorkTypeName" title="Druh" locked={false} width={120} />
                <ExcelExportColumn field="Note" title="Popis" locked={false} width={120} />
                {haveRight("View_Out_Values") && <ExcelExportColumn field="NoteOut" title="Popis out" locked={true} width={50} />}

                {haveRight("View_In_Values") && <ExcelExportColumn field="TimeSpentInExcel" title="Trvání in" locked={false} width={120}
                                                                   cellOptions={{
                                                                       format: "[h]:mm;@",
                                                                   }}
                />}
                {haveRight("View_In_Values") && <ExcelExportColumn field="PriceIn" title="Cena in" locked={false} width={120} />}
                {haveRight("View_In_Values") && <ExcelExportColumn field="CurrencyInName" title="Měna in" locked={false} width={120} />}

                {haveRight("View_Out_Values") && <ExcelExportColumn field="TimeSpentOutEffectiveExcel" title="Trvání out" locked={false} width={120}
                                                                    cellOptions={{
                                                                        format: "[h]:mm;@",
                                                                    }}
                />}
                {haveRight("View_Out_Values") && <ExcelExportColumn field="PriceOut" title="Cena out" locked={false} width={120} />}
                {haveRight("View_Out_Values") && <ExcelExportColumn field="CurrencyOutName" title="Měna out" locked={false} width={120} />}

                <ExcelExportColumn field="Invoiced" title="Fakturováno" locked={false} width={120} />

                <ExcelExportColumn field="InvoiceNum" title="Faktura" locked={false} width={120} />
                <ExcelExportColumn field="ClientPartnerName" title="Partner" locked={false} width={120} />

            </ExcelExport>


            {data && currency && workTypes && authors && projects &&

                <Grid

                    ref={_grid}
                    //pageable={true}
                    sortable={true}
                    filterable={true}
                    //scrollable={"virtual"} //virtual, scrollable
                    resizable={true}
                    reorderable={true}
                    //rowHeight={45}
                    // onItemChange={itemChange}

                    editField="inEdit"
                    //pageable={true}
                    size={"small"}


                style={{
                    height: "100%", //"calc(100% - 55px)", // (refSize.height - 150) / 2,//"500px",
                    minHeight: 20,
                    //maxHeight: 300, // refSize.height - 70,
                    width: refSize.width - menuSize,
                }}
                data={process(data, dataState)}{...dataState}

                // data={process(data, dataState).slice(page.skip, page.take + page.skip)}{...dataState}
                // skip={page.skip}
                // take={page.take}
                total={process(data, dataState).length}
                pageable={{
                    buttonCount: 4,
                    pageSizes: [10, 50, 100, 500],
                    pageSizeValue: pageSizeValue,
                }}
                onPageChange={pageChange}

                //onPageChange={pageChange}
                onDataStateChange={(e) => setDataState(e.dataState)}

                onRowDoubleClick={rowDblClick}

                >

                    <GridToolbar>

                        <button
                            title="New"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={editNew}
                        >
                            Novy Vykaz
                        </button>

                        {/*{dataType==="project" && <button*/}
                        {/*    title="Import from csv"*/}
                        {/*    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"*/}
                        {/*    onClick={showImport}*/}
                        {/*>*/}
                        {/*    Import from csv*/}
                        {/*</button>}*/}

                        <ToolbarSeparator />

                        {haveRight("Prices_recalculate") && <button
                            title="New"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-warning"
                            onClick={Recalculate}
                        >
                            Recalculate
                        </button>}


                        <ToolbarSpacer />

                        <button
                            title="Excel"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={excelExport}
                        >
                            Export Excel
                        </button>

                        {/*<Button*/}
                        {/*    title={"Bulk Action"}*/}
                        {/*    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"*/}
                        {/*    onClick={bulkEdit}*/}
                        {/*>*/}
                        {/*    bulk edit*/}
                        {/*</Button>*/}


                        {/*<ToolbarSeparator />*/}
                        {/*<ToolbarItem>zobrazeno: {selectedCount} z celkem {data.length}</ToolbarItem>*/}

                    </GridToolbar>

                    <Column field="ID" title="ID" width="80px" filterable={true} editable={false} />

                {/*<Column cell={myID} field="ID" title="ID" width="80px" footerCell={myFooterCell} filterable={true}/>*/}
                {/*<Column cell={myID} field="ID" title="ID" width="80px" filterable={true} locked/>*/}

                    <Column field="ProjectID" minResizableWidth={120} title="Projekt" width={300} locked
                            cell={useProjectEditor} filterCell={ProjectFilter} footerCell={CountFooter} />
                    <Column field="AuthorID" minResizableWidth={120} title="Autor" cell={useAuthorEditor}
                            filterCell={AuthorFilter} width={200} editable={true} locked />

                    {haveRight("View_Out_Values") && <Column field="AuthorOutID" minResizableWidth={120} title="Autor out" cell={useAuthorEditor}
                            filterCell={AuthorFilter} width={200} editable={true} />}

                    <Column field="Date" minResizableWidth={100} title="Datum" width={150}
                            filter="date"
                            format="{0:dd.MM.yyyy}"
                            editor="date"
                            //cell={DateCell}
                            filterCell={useDateFilter}
                    />

                    {/*<Column field="Origin" minResizableWidth={120} title="Origin" width={120}/>*/}
                    {/*<Column field="ImportID" minResizableWidth={120} title="Import" width={120}/>*/}

                    <Column field="WorkTypeID" minResizableWidth={120} title="Druh" cell={useWorkTypeEditor} filterCell={WorkTypeFilter} width={120}/>

                    {/*<Column field="IssueID" minResizableWidth={120} title="Issue" width={150}/>*/}
                    {/*<Column field="IssueSummary" minResizableWidth={120} title="Issue desc" width={250}/>*/}

                    <Column field="Note" minResizableWidth={120} title="Popis" width={450}/>

                    {haveRight("View_Out_Values") && <Column field="NoteOut" minResizableWidth={120} title="Popis out" width={450}/>}

                    {/*<Column title={"In"}>*/}
                    {haveRight("View_In_Values") && <Column field="TimeSpentIn" cell={DurationEditor} minResizableWidth={120} title="Trvání In"
                             width={120} filter={"numeric"} footerCell={DurationFooter}/>}
                    {haveRight("View_In_Values") && <Column field="PriceIn" minResizableWidth={120} title="Cena In" width={120} editor={"numeric"}
                             editable={false} filter={"numeric"} className={"red right"} footerCell={SumFooter}/>}
                    {haveRight("View_In_Values") && <Column field="CurrencyInID" minResizableWidth={120} title="Měna In" cell={useCurrencyEditor}
                             filterCell={CurrencyFilter} width={120}/>}
                    {/*</Column>*/}
                    {/*<Column title={"Out"}>*/}
                    {haveRight("View_Out_Values") && <Column field="TimeSpentOut" cell={DurationEditor} minResizableWidth={120} title="Trvání Out"
                             width={120} filter={"numeric"} footerCell={DurationFooter}/>}
                    {haveRight("View_Out_Values") && <Column field="TimeSpentOutEffective" cell={DurationEditor} minResizableWidth={120} title="Trvání Out Effective"
                                                             width={120} filter={"numeric"} footerCell={DurationFooter}/>}
                    {haveRight("View_Out_Values") && <Column field="PriceOut" minResizableWidth={120} title="Cena Out" width={120} editor={"numeric"}
                             editable={false} filter={"numeric"} className={"green right"} footerCell={SumFooter}/>}
                    {haveRight("View_Out_Values") && <Column field="CurrencyOutID" minResizableWidth={120} title="Měna Out" cell={useCurrencyEditor}
                             filterCell={CurrencyFilter} width={120}/>}
                    {/*</Column>*/}
                    <Column field="Invoiced" minResizableWidth={120} title="Fakturovano" width={120} />
                    <Column field="InvoiceNum" minResizableWidth={120} title="Faktura" width={120}/>
                    {/*<Column field="Tags" minResizableWidth={120} title="Tagy" width={120}/>*/}

                    <Column field="ClientPartnerID" minResizableWidth={120} title="Partner" cell={usePartnerEditor}
                            filterCell={PartnerFilter} width={200} editable={false} />

                    <Column field="action" minResizableWidth={180}
                            cell={MyEditCommandCell}
                        // columnMenu={ActionCell}
                            filterable={false}
                            headerCell={ActionCell}
                            width="180"
                            resizable={true}
                            locked
                        // filterCell={ActionCell}
                    />

                </Grid>

            }


            {openForm && <EditTms
                projects={projects}
                authors={authors}
                workTypes={workTypes}
                currencies={currency}
                editFor={dataType}
                selectedClient={selectedClient}
                selectedProject={selectedProject}
                actualUser={actual_user}
                cancelEdit={handleCancelEdit}
                onSubmit={handleSubmit}
                item={editItem}
            />}


            {openFormBulk && <BulkEditTms
                projects={projects}
                authors={authors}
                workTypes={workTypes}
                currencies={currency}
                cancelEdit={bulkClose}
                onSubmit={bulkSubmit}
                item={editItem}
            />}


            {showConfirmDelete && <Confirm
                action={deleteRowAction}
                cancel={cancelDelete}
                text={"delete item?"}
            />}

            {openFormRecalculate && <ConfirmRecalculate
                actionAll={RecalculateAll}
                actionUnInvoiced={RecalculateUnInvoiced}
                cancel={RecalculateClose}
                text={"Recalculate price?"}
            />}


            {showFilesDialog && <FileDialog
                close={closeFiles}
                text={"delete item?"}
                filesFor={showFilesFor}
            />}

            {showImportDialog && <ImportDialog
                close={closeImport}
                text={"delete item?"}
                forProject={selectedProject}
            />}


            {isLoading && <Waiting />}

                {/*<div className="list-footer"></div>*/}


                {/*{openForm && <EditClient*/}
                {/*    cancelEdit={handleCancelEdit}*/}
                {/*    onSubmit={handleSubmit}*/}
                {/*    item={editItem}*/}
                {/*/>}*/}

        </>
    )
}

export default TmsGrid