import {DropDownList} from "@progress/kendo-react-dropdowns";


const ClientEditor = props => {

    // debugger
    const clients = props.clients

    const handleChange = (e) => {
        //debugger
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.ID,
            });
        }
    };
    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    const SelectedClient=clients.find((c) => c.ID === dataValue)

    return(
        <td
            style={{...props.style}} // this applies styles that lock the column at a specific position
            className={props.className} // this adds classes needed for locked columns
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
        >
            {/*{dataItem.inEdit ? (*/}
            {/*    <DropDownList*/}
            {/*        style={{*/}
            {/*            width: "100%",*/}
            {/*        }}*/}
            {/*        onChange={handleChange}*/}
            {/*        value={clients.find((c) => c.ID === dataValue)}*/}
            {/*        data={clients}*/}
            {/*        textField="Name"*/}
            {/*    />*/}
            {/*) : (*/}
            {/*    SelectedClient.Name*/}
            {/*)}*/}

            {SelectedClient?.Name}

        </td>
    )
}

export default ClientEditor